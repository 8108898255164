
@use '@angular/material' as mat;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@include mat.core();

$rc-green-set: (
	50: #edfffb,
	100: #c2fff7,
	200: #86fff0,
	300: #41ffe7,
	400: #0af7d8,
	500: #00dac0,
	600: #00b09e,
	700: #008b7f,
	800: #01756d,
	900: #075a55,
	950: #003837,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		950 : #ffffff
	)
);

$rc-yellow-set: (
	50: #fefbe8,
	100: #fff8c2,
	200: #ffef87,
	300: #ffde43,
	400: #ffc914,
	500: #efaf03,
	600: #ce8600,
	700: #a45e04,
	800: #88490b,
	900: #733c10,
	950: #431e05,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		950: #ffffff
	)
);

$rc-red-set: (
	50: #fef2f2,
	100: #fee2e2,
	200: #fecaca,
	300: #fca5a5,
	400: #f87171,
	500: #ef4444,
	600: #dc2626,
	700: #b91c1c,
	800: #991b1b,
	900: #7f1d1d,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff
	)
);

$rev-cyclone-primary: mat.m2-define-palette($rc-green-set,800);
$rev-cyclone-accent: mat.m2-define-palette($rc-yellow-set,400);

$rev-cyclone-warn: mat.m2-define-palette($rc-red-set,400);

$rev-cyclone-theme: mat.m2-define-light-theme((
	color: (
		primary: $rev-cyclone-primary,
		accent: $rev-cyclone-accent,
		warn: $rev-cyclone-warn,
	),
	typography: mat.m2-define-typography-config(),
	density: 0
));

@include mat.all-component-themes($rev-cyclone-theme);

html,
body {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: Inter, sans-serif;
	box-sizing: border-box;
}

html {
	display: flex;
	position: fixed;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
}

@media print {
	.no-print{
		display: none !important;
	}

	html{
		position: static;
	}
}
